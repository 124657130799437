import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import CalculateIcon from '@mui/icons-material/Calculate';
import DiscountIcon from '@mui/icons-material/Discount';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ComputerIcon from '@mui/icons-material/Computer';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <AppBar position="static">
      <Toolbar>
        <MenuItem
          onClick={() => {
            navigate("/profit-calc");
          }}
        >
          <CalculateIcon />
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/coupon-generator");
          }}
        >
          <DiscountIcon />
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/order-totals");
          }}
        >
          <CurrencyExchangeIcon />
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/schedule-testfantasiastore");
          }}
        >
          <ComputerIcon />
        </MenuItem>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

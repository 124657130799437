export const profitTableData = {
    firstTable: [
        {
            label: "Prezzo di vendita",
            value: "sellPrice"
        },
        {
            label: "Prezzo di acquisto",
            value: "purchasePrice"
        },
        {
            label: "Iva sulla vendita",
            value: "sellVat"
        },
        {
            label: "Iva sull'acquisto",
            value: "buyVAT"
        },
        {
            label: "Costo dell'IVA",
            value: "VATtotal"
        },
        {
            label: "Costi di spedizione",
            value: "shippingCosts"
        },
        {
            label: "Guadagno netto",
            value: "netProfit"
        },
        {
            label: "Margine",
            value: "profitPercentage"
        }
    ],
    secondTable: [
        {
            label: "Commissioni nette AMZ",
            value: "netCommissionsAmz"
        },
        {
            label: "Commissioni nette eBay",
            value: "netCommissionsEbay"
        },
        {
            label: "Commissioni nette IBS",
            value: "netCommissionsIBS"
        },
        {
            label: "Commissioni nette Stripe",
            value: "netCommissionsStripe"
        },
        {
            label: "Commissioni nette Paypal",
            value: "netCommissionsPaypal"
        }
    ]
}